<template>
  <div eagle-list="root">
    <div class="elevation-4 px-4 py-4">
      <!-- 列表搜尋/篩選 -->
      <listSearchAndFilter
        class="my-4"
        :listKey="listKey"
      ></listSearchAndFilter>

      <!-- 列表工具列 -->
      <listToolbar
        class="my-4"
        :listKey="listKey"
      ></listToolbar>

      <!-- 列表資訊 -->
      <listInfo
        class="my-4"
        :list-key="listKey"
      ></listInfo>

      <div eagle-list="data-top"></div>

      <!-- 卡片模式 -->
      <listCardMode
        class="my-4"
        v-if="listDisplayMode === 'card'"
        :listKey="listKey"
      ></listCardMode>

      <!-- 表格模式 -->
      <listTableMode
        class="my-4"
        v-if="listDisplayMode === 'table'"
        :listKey="listKey"
      ></listTableMode>

      <!-- 分頁 -->
      <v-pagination
        v-if="createPager"
        v-model="page"
        :length="pager.pages"
        :total-visible="7"
      ></v-pagination>
    </div>
  </div>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    listKey: String,
  },
  computed: {
    listSelected() {
      return this.$store.getters[`list/${this.listKey}/selected`]
    },
    page: {
      get() {
        return this.$store.getters[`list/${this.listKey}/page`]
      },
      set(value) {
        this.$store.dispatch(`list/${this.listKey}/set`, {
          key: 'page',
          value,
        })

        this.listActions.changeIndexCondition(true)
      },
    },
    listActions() {
      return this.$store.getters[`list/${this.listKey}/actions`]
    },
    createPager() {
      if(!this.pager) return false
      return this.pager.pages > 1
    },
    pager() {
      return this.$store.getters[`list/${this.listKey}/pager`]
    },
    listDisplayMode() {
      return this.$store.getters[`list/${this.listKey}/displayMode`]
    },
    selected() {
      return this.$store.getters[`list/${this.listKey}/selected`]
    },
    isPopupMode() {
      return this.$store.getters[`list/${this.listKey}/isPopupMode`]
    },
  },
  watch: {
    selected: {
      deep: true,
      handler() {
        if(!this.isPopupMode) return
        this.$emit('updatePopupSelectData', window.eagleLodash.cloneDeep(this.selected))
      },
    },
  },
  components: {
    listInfo: () => import('@/components/list/listInfo'),
    listToolbar: () => import('@/components/list/listToolbar'),
    listSearchAndFilter: () => import('@/components/list/listSearchAndFilter.vue'),
    listCardMode: () => import('@/components/list/listCardMode'),
    listTableMode: () => import('@/components/list/listTableMode'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
