import {ActionContext} from 'vuex/types'
import {EagleListConfigInterface, EagleListSortInterface} from 'components/list/types/list'
import { PopupConfigInterface } from 'plugins/anchorPopup/types'

const handleIndexFilterItem = (filter: any, filterConfig: any, key: string) => {
  const value = filter[key]
  const filterConfigItem = filterConfig[key]

  if(!filterConfigItem){
    return value
  }

  if(typeof filterConfigItem.indexFilterValue == 'function') {
    return filterConfigItem.indexFilterValue(value)
  }

  return  value
}

const handleIndexFilter = (filter: any, filterConfig: any) => {
  const result:any = {}
  for(const key in filter) {
    result[key] = handleIndexFilterItem(filter, filterConfig, key)
  }
  return result
}

declare interface ListInstanceStateInterface {
  bindRoute: boolean,
  hasWritePermission: boolean,
  config: AnyObject,
  api: AnyObject | null,
  data: any[],
  perPage: number,
  page: number|string,
  pager: AnyObject|null,
  searchKeyword: string|null,
  meta: null,
  filter: AnyObject|null,
  actions: AnyObject|null,
  displayMode: 'card'|'table',
  displayModeTypes: string[]|null,
  selected: string[],
  popupConfig: PopupConfigInterface|null,
  indexParams: AnyObject,
  sort: {
    key: null|string,
    type: 'asc'|'desc',
    defaultKey: null|string,
    defaultType: 'asc'|'desc',
  },
  scrollTopAfterIndex: boolean,
}

declare interface ListInstanceInterface {
  namespaced: boolean
  state: () => ListInstanceStateInterface
  mutations: AnyObject
  actions: AnyObject
  getters: AnyObject
}

declare interface ListInstanceOptionInterface {
  config: EagleListConfigInterface
  bindRoute: boolean
  hasWritePermission: boolean
}

const getFirstSortOptionKey = (sortOptions: EagleListSortInterface[]|undefined) : string|null => {
  if(!Array.isArray(sortOptions)) return null
  if(!sortOptions[0]) return null
  return sortOptions[0].key || null
}

export default (options: ListInstanceOptionInterface) : ListInstanceInterface => {
  const {
    config,
    bindRoute,
    hasWritePermission
  } = options

  return {
    namespaced: true,
    state: () => ({
      scrollTopAfterIndex: false,
      bindRoute,
      hasWritePermission,
      config,
      searchKeyword: null,
      meta: null,
      filter: null,
      api: null,
      data: [],
      sort: {
        defaultKey: getFirstSortOptionKey(config.sort),
        defaultType: !config.sortType ? 'desc' : config.sortType,
        key: getFirstSortOptionKey(config.sort),
        type: !config.sortType ? 'desc' : config.sortType,
      },
      perPage: !config.perPage ? 20 : config.perPage,
      page: 1,
      pager: null,
      actions: {},
      displayMode: !config.displayMode ? 'table' : config.displayMode,
      displayModeTypes: null,
      selected: [],
      popupConfig: null,
      indexParams: {},
    }),
    mutations: {
      set: (state: ListInstanceStateInterface, data: {[key:string]: any}) => {
        // @ts-ignore
        state[data.key] = data.value
        window.eagleLodash.set(state, data.key, data.value)
      },
      setResult: (state: ListInstanceStateInterface, result: any) => {
        if(result.pager) {
          state.pager = result.pager
          state.data = result.data
        }

        else {
          state.pager = null
          state.data = result
        }
      },
      setActions: (state: ListInstanceStateInterface, actions: any) => {
        state.actions = actions
      },
      setApi: (state: ListInstanceStateInterface, api: { [key:string]: any }) => {
        state.api = api
      },
      setSelected(state: ListInstanceStateInterface, data: []) {
        state.selected = data
      },
      select(state: ListInstanceStateInterface, data: any) {
        const selected = window.eagleLodash.cloneDeep(state.selected)
        selected.push(data)
        state.selected = selected
      },
      unselect(state: ListInstanceStateInterface, data: any) {
        state.selected.push(data)
        const selected = window.eagleLodash.cloneDeep(state.selected)
        // @ts-ignore
        window.eagleLodash.remove(selected, item => window.eagleLodash.isEqual(data, item))
        state.selected = selected
      },
      updateRow(state: ListInstanceStateInterface, config: { rowIndex: number, row: any }) {
        const data = window.eagleLodash.cloneDeep(state.data)
        data[config.rowIndex] = config.row
        state.data = data
      },
      setFilter(state: ListInstanceStateInterface, filter: { [key:string]: any }|null) {
        state.filter = window.eagleLodash.cloneDeep(filter)
      },
      setMeta(state: ListInstanceStateInterface, meta: { [key:string]: any }|null) {
        state.meta = window.eagleLodash.cloneDeep(meta)
      },
    },
    actions: {
      set: (context: ActionContext<any, any>, data: {[key:string]: any}) => {
        context.commit('set', data)
      },
      setResult: (context: ActionContext<any, any>, result: any) => {
        context.commit('setResult', result)
      },
      setActions: (context: ActionContext<any, any>, actions: any) => {
        context.commit('setActions', actions)
      },
      setApi: (context: ActionContext<any, any>, api: { [key:string]: any }) => {
        context.commit('setApi', api)
      },
      setSelected: (context: ActionContext<any, any>, data: []) => {
        context.commit('setSelected', data)
      },
      select: (context: ActionContext<any, any>, data: any) => {
        context.commit('select', data)
      },
      unselect: (context: ActionContext<any, any>, data: any) => {
        context.commit('unselect', data)
      },
      updateRow: (context: ActionContext<any, any>, config: { rowIndex: number, row: any }) => {
        context.commit('updateRow', config)
      },
      setFilter: (context: ActionContext<any, any>, filter: { [key:string]: any }|null) => {
        context.commit('setFilter', filter)
      },
      setMeta: (context: ActionContext<any, any>, meta: { [key:string]: any }|null) => {
        context.commit('setMeta', meta)
      },
    },
    getters: {
      config: (state: ListInstanceStateInterface) => state.config,
      targetKey: (state: ListInstanceStateInterface, getters: AnyObject) => {
        if(!state.config) return 'id'
        if(!state.config.targetKey) return 'id'
        return state.config.targetKey
      },
      dataAction: (state: ListInstanceStateInterface, getters: AnyObject) => {
        return window.eagleLodash.get(getters.config, ['dataAction'])
      },
      cardConfig: (state: ListInstanceStateInterface, getters: AnyObject) => {
        return window.eagleLodash.get(getters.config, ['card'])
      },
      tableConfig: (state: ListInstanceStateInterface, getters: AnyObject) => {
        return window.eagleLodash.get(getters.config, ['table'])
      },
      tableDataConfig: (state: ListInstanceStateInterface, getters: AnyObject) => {
        return window.eagleLodash.get(getters.tableConfig, ['data'])
      },
      data: (state: ListInstanceStateInterface) => state.data,
      dataQuantity: (state: ListInstanceStateInterface) => {
        if(!Array.isArray(state.data)) return 0
        return state.data.length
      },
      dataTotalQuantity: (state: ListInstanceStateInterface, getters: AnyObject) => {
        if(getters.pager) return getters.pager.total
        if(!Array.isArray(state.data)) return 0
        return state.data.length
      },
      api: (state: ListInstanceStateInterface) => state.api,
      actions: (state: ListInstanceStateInterface) => state.actions,
      page: (state: ListInstanceStateInterface) => state.page,
      pager: (state: ListInstanceStateInterface) => state.pager,
      displayMode: (state: ListInstanceStateInterface) => state.displayMode,
      displayModeTypes: (state: ListInstanceStateInterface, getters: AnyObject) => {
        return window.eagleLodash.get(getters.config, ['displayModeTypes'])
      },
      selected: (state: ListInstanceStateInterface) => state.selected,
      hasSelected: (state: ListInstanceStateInterface, getters: AnyObject) => {
        if(!Array.isArray(getters.selected)) return false
        return getters.selected.length > 0
      },
      hasSelectedAll: (state: ListInstanceStateInterface, getters: AnyObject) => {
        if(!Array.isArray(getters.selected)) return false
        if(!Array.isArray(getters.data)) return false
        if(getters.selected.length == 0) return false
        return getters.selected.length === getters.data.length
      },
      hasData: (state: ListInstanceStateInterface, getters: AnyObject) => {
        if(!Array.isArray(getters.data)) return false
        return getters.data.length > 0
      },
      perPageOptions: (state: ListInstanceStateInterface, getters: AnyObject) => {
        const defaultOptions = [10, 20, 30]
        if(!getters.config) return defaultOptions
        const perPageOptions= window.eagleLodash.get(getters.config, ['perPageOptions'])
        if(!Array.isArray(perPageOptions)) return defaultOptions
        return perPageOptions
      },
      perPage: (state: ListInstanceStateInterface) => {
        if(!state.perPage) return 20
        // @ts-ignore
        const perPage= parseInt(state.perPage)
        if(!perPage) return 20
        if(isNaN(perPage)) return 20
        return perPage
      },
      bindRoute: (state: ListInstanceStateInterface) => state.bindRoute,
      hasWritePermission: (state: ListInstanceStateInterface, getters: AnyObject) => state.hasWritePermission,
      sortOptions: (state: ListInstanceStateInterface, getters: AnyObject) => {
        const sortOptions = window.eagleLodash.get(getters.config, ['sort'])
        if(!Array.isArray(sortOptions)) return null
        if(sortOptions.length == 0) return null
        return sortOptions
      },
      sortKey: (state: ListInstanceStateInterface) => window.eagleLodash.get(state, ['sort', 'key']),
      sortType: (state: ListInstanceStateInterface) => window.eagleLodash.get(state, ['sort', 'type']),
      defaultSortKey: (state: ListInstanceStateInterface) => window.eagleLodash.get(state, ['sort', 'defaultKey']),
      defaultSortType: (state: ListInstanceStateInterface) => window.eagleLodash.get(state, ['sort', 'defaultType']),
      scrollTopAfterIndex: (state: ListInstanceStateInterface) => state.scrollTopAfterIndex,
      batchConfig: (state: ListInstanceStateInterface, getters: AnyObject) => {
        return window.eagleLodash.get(getters, ['config', 'batch'])
      },
      searchKeyword: (state: ListInstanceStateInterface) => state.searchKeyword,
      filterConfig: (state: ListInstanceStateInterface, getters: AnyObject) => {
        return window.eagleLodash.get(getters.config, ['filter'])
      },
      exportExcelConfig: (state: ListInstanceStateInterface, getters: AnyObject) => {
        return window.eagleLodash.get(getters.config, ['exportExcel'])
      },
      filter: (state: ListInstanceStateInterface) => state.filter,
      indexFilter: (state: ListInstanceStateInterface, getters: AnyObject) => {
        return handleIndexFilter(window.eagleLodash.cloneDeep(getters.filter), getters.filterConfig)
      },
      meta: (state: ListInstanceStateInterface) => state.meta,
      popupConfig: (state: ListInstanceStateInterface) : PopupConfigInterface|null => state.popupConfig,
      isPopupMode: (state: ListInstanceStateInterface) : boolean => !!state.popupConfig,
      hasSelectFeature: (state: ListInstanceStateInterface, getters: AnyObject) => {
        if(getters.isPopupMode) return true
        if(getters.batchConfig) return true
        return false
      },
      indexParams: (state: ListInstanceStateInterface) : AnyObject => state.indexParams,
    },
  }
}
